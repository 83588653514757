.App {
    min-height: 100vh;
}

/* HOME */
.home {
    padding-left: 48px;
    padding-right: 48px;
    margin-bottom: 50px;
}

/* PAGE LAYOUT */
.page-layout {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* ROUTES */

.underline,
#link-text {
    text-decoration: underline;
}

.route-links,
a:-webkit-any-link {
    color: inherit;
    text-decoration: none;
}

.route-links:visited {
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}


/* BANNER */

.login-banner {
    background-color: #E6E6E6;
    padding: 20px;
}

.login-button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.banner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding: 15px;
}

.username {
    font-weight: bold;
}


/* LOADER */
.page-loader.app-page-loader {
    min-height: 100vh;
}

.page-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader {
    height: 5rem;
    width: 5rem;

    animation: spin 2s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* SIDEBAR */
.toggle-span {
    display: flex;
    align-items: center;
}

/* BOTTOM NAVIGATION */
.bottom-nav {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.bottom-nav-box {
    flex: 0 1 40%;
    min-height: 60px;
    background-color: #F2F2F4;
    padding: 10px;
}

.bottom-nav-box:hover {
    background-color: #e8e8ef;

}

.bottom-nav-section-title {
    font-weight: bold;
}

.bottom-nav-direction {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: smaller;
}

/* GRID */
.grid {
    display: grid;
    grid-template-columns: 25% 75%;
    column-gap: 15px;
    row-gap: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
}

/* DOWNLOADS */

.csv-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
}

.csv-button {
    background-color: white;
    border: solid 1px black;
    border-radius: 2px;
    padding: 5px 8px;
    box-shadow: 2px 2px 2px #00000029;
    cursor: pointer;
}

.csv-button:hover {
    background-color: rgb(231, 225, 225);
}

/* FACTOR RADIO BUTTON/CHECKBOX */
.checkbox-factor-container {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
}

.radio-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 2.5px;
}

.radio-checkbox-container {
    columns: 2;
    margin-left: 15px;
    column-width: auto;
}

/* CARD SELECTION */
.report-card {
    border: black 1.5px solid;
    border-radius: 2%;
    padding: 10px;
    min-height: 175px;
    min-width: 275px;
}

.report-card-inactive {
    border: lightgrey 1.5px solid;
    border-radius: 2%;
    padding: 10px;
    min-height: 175px;
    min-width: 275px;
}


.report-card-text {
    text-decoration: underline;
    cursor: pointer;
}

.report-card-text-inactive,
.report-card-inactive>* {
    color: grey;
}

.report-cards {
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
}

/* TABLE */
table {
    border-collapse: collapse;
    width: 100%;
}

.data-table-container {
    overflow-x: auto;
}


tbody>tr:nth-child(even) td,
tbody>tr:nth-child(even) th:not(.no-alt-color) {
    background-color: #EBEBEB;
}


.preoffer-offer-table {
    .row-header {
        min-width: 100px;
    }
}

th {
    font-weight: normal;
}

thead>tr>th {
    min-width: 60px;
    hyphens: auto;
}

.institution-cohort-header {
    min-width: 50px;
}


th,
td {
    text-align: center;
    min-width: 40px;
    /* max-width: 80px; */

}

.grants-table>tbody>tr>td {
    min-width: 110px;
}

.row-header {
    text-align: left;
    border-right: 2px #D3D3D3 solid;
    padding: 4px;
}

.row-split {
    border-right: 2px #D3D3D3 solid;
}

.column-headers,
.row-split-type {
    border-bottom: 2px #D3D3D3 solid;
}

.grand-total-row {
    border-top: 2px #D3D3D3 solid;
    border-bottom: 2px #D3D3D3 solid;
}

.last-row-split {

    th,
    td {
        border-bottom: 2px #D3D3D3 solid;
    }
}

.offer-type-title {
    font-weight: 700;
}

/* GRID LAYOUT */

.grid {
    display: grid;
    grid-template-columns: 25% 75%;
    column-gap: 15px;
    row-gap: 10px;
    padding: 25px;
}

.grid-row-title {
    font-weight: bold;
}

/* HEADERS AND TEXT */

h1,
h2,
h3,
h4,
.red-variable-name {
    color: #A51C30;
}

.header {
    margin-top: 50px;
    text-decoration: underline;
}

.red-variable-name {
    font-style: italic;
}

.confidentiality-commitment-text {
    font-family: "garamond";
    font-weight: 600;
}

.cohort-list {
    columns: 2;
}

.factor-option-header {
    font-weight: 700;
}

.italicize-sub-header {
    color: #A51C30;
    font-style: italic;
    font-weight: 300;
}

.disclaimer-note {
    font-style: italic;
    font-weight: 300;
}

.horizontal-line {
    border-top: 1px solid black;
}

.grid-row-title {
    font-weight: bold;
}

.grid-row-title-header {
    font-weight: bold;
    font-style: italic;
}

.academic-area-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#email-link {
    color: blue;
    text-decoration: underline;
}

.plot-d6a7b5-swatch {
    font-size: 12px;
}

.plot-d6a7b5-swatches-wrap {
    display: flex;
    align-items: center;
    min-height: 33px;
    flex-direction: column;
    width: auto;
}

.plot-d6a7b5-swatches-wrap .plot-d6a7b5-swatch {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5em;
    width: calc(100%);
    margin-left: 0.5em;
}

/* OVERLAPPING HISTOGRAM */

.plot-container {
    display: flex;
    flex-wrap: wrap;
}

.chart-container {
    margin: 10px;
}

/* MEDIA */

@media print {

    header,
    footer,
    aside,
    form,
    .sidebar,
    .breadcrumbs,
    .bottom-nav {
        display: none;
    }
}